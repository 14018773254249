var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "LBL0002109" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  (!_vm.preStartupCheck.equipments ||
                    _vm.preStartupCheck.equipments.length === 0) &&
                  Boolean(_vm.param.sopPrestartupCheckId)
                    ? _c(
                        "q-chip",
                        {
                          staticClass: "no-border",
                          staticStyle: { "margin-right": "5px !important" },
                          attrs: {
                            square: "",
                            outline: "",
                            color: "red",
                            "text-color": "white",
                            icon: "report",
                            size: "12px",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$label("LBL0002110")) + " ")]
                      )
                    : _vm._e(),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: Boolean(_vm.param.sopPrestartupCheckId),
                        expression: "Boolean(param.sopPrestartupCheckId)",
                      },
                    ],
                    attrs: {
                      disabled:
                        !_vm.preStartupCheck.equipments ||
                        _vm.preStartupCheck.equipments.length === 0,
                      label: "LBL0002111",
                      icon: "print",
                    },
                    on: { btnClicked: _vm.checklistPrint },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: Boolean(_vm.param.sopPrestartupCheckId),
                        expression: "Boolean(param.sopPrestartupCheckId)",
                      },
                    ],
                    attrs: {
                      disabled:
                        !_vm.preStartupCheck.equipments ||
                        _vm.preStartupCheck.equipments.length === 0,
                      label: "LBL0002112",
                      icon: "print",
                    },
                    on: { btnClicked: _vm.checklistReportPrint },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editInfo &&
                          !_vm.disabled &&
                          Boolean(_vm.param.sopPrestartupCheckId),
                        expression:
                          "editInfo && !disabled && Boolean(param.sopPrestartupCheckId)",
                      },
                    ],
                    attrs: { label: "LBLREMOVE", icon: "remove" },
                    on: { btnClicked: _vm.removePreStartupCheck },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editInfo &&
                          !_vm.disabled &&
                          Boolean(_vm.param.sopPrestartupCheckId),
                        expression:
                          "editInfo && !disabled && Boolean(param.sopPrestartupCheckId)",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isComplete,
                      url: _vm.saveUrl,
                      param: _vm.preStartupCheck,
                      mappingType: "PUT",
                      label: "LBLCOMPLETE",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.completePreStartupCheck,
                      btnCallback: _vm.completeCallback,
                    },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editInfo && !_vm.disabled,
                        expression: "editInfo && !disabled",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.preStartupCheck,
                      mappingType: _vm.mappingType,
                      label: "LBLSAVE",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.savePreStartupCheck,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-9" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          label: "LBL0002103",
                          name: "checkName",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkName,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "checkName", $$v)
                          },
                          expression: "preStartupCheck.checkName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-select", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          codeGroupCd: "PSR_EQUIPMENT_STATUS_CD",
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "psrEquipmentStatusCd",
                          label: "LBL0002100",
                        },
                        model: {
                          value: _vm.preStartupCheck.psrEquipmentStatusCd,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "psrEquipmentStatusCd",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.psrEquipmentStatusCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-dept", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          type: "edit",
                          label: "LBL0002101",
                          name: "checkDeptCd",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "checkDeptCd", $$v)
                          },
                          expression: "preStartupCheck.checkDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-field", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          plantCd: _vm.preStartupCheck.plantCd,
                          type: "user",
                          label: "LBL0002113",
                          name: "checkCancarrierId",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkCancarrierId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "checkCancarrierId",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.checkCancarrierId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          label: "LBL0002114",
                          name: "checkPurpose",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkPurpose,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "checkPurpose", $$v)
                          },
                          expression: "preStartupCheck.checkPurpose",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editInfo,
                          type: "edit",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.preStartupCheck.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "plantCd", $$v)
                          },
                          expression: "preStartupCheck.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-3" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          rows: 3,
                          label: "LBL0002115",
                          name: "checkResultTotal",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkResultTotal,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "checkResultTotal",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.checkResultTotal",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ],
        2
      ),
      _c("c-tab", {
        staticClass: "q-mt-sm preStartup-tab-no-padding",
        attrs: {
          type: "tabcard",
          align: "left",
          height: _vm.tabHeight,
          editable: _vm.editInfo,
          tabItems: _vm.preStartupCheck.equipments,
          inlineLabel: true,
          addTab: _vm.editInfo && !_vm.disabled,
          removeTab: _vm.editInfo && !_vm.disabled,
          removeConditionCol: "checkCompleteFlag",
          removeConditionVal: "Y",
        },
        on: {
          "update:tabItems": function ($event) {
            return _vm.$set(_vm.preStartupCheck, "equipments", $event)
          },
          "update:tab-items": function ($event) {
            return _vm.$set(_vm.preStartupCheck, "equipments", $event)
          },
          addTab: _vm.addTab,
          removeTab: _vm.removeTab,
          tabClick: _vm.tabClick,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    param: _vm.param,
                    preStartupCheck: _vm.preStartupCheck,
                    equipmentCd: tab.equipmentCd,
                    research: _vm.research,
                  },
                  on: {
                    "update:preStartupCheck": function ($event) {
                      _vm.preStartupCheck = $event
                    },
                    "update:pre-startup-check": function ($event) {
                      _vm.preStartupCheck = $event
                    },
                    "update:research": function ($event) {
                      _vm.research = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }